import { CommonLayout } from "@/components/Layouts"
import { Metadata } from "@/components/Metadata"
import { NextPageWithLayout } from "../_app"
import GalleryInspiration from "@/components/Explore/Gallery/GalleryInspiration"

const Home: NextPageWithLayout = () => {
  return (
    <>
      <Metadata
        title="GAIA | Creation Engine for the AI Age"
        description="A collaborative multi-modality AI platform, GAIA is an ambitious movement to give human and AI agents superpowers, uncensored & equitable."
        type="website"
      />
      <GalleryInspiration type="images" />
    </>
  )
}

Home.getLayout = page => <CommonLayout isExplore>{page}</CommonLayout>
Home.showLoading = () => false

export default Home
